import axios from "axios"
import { baseURL } from "constants/api"

export const getQRCodes = async (restaurant_id) => {
  try {
    console.log("getQRCodes_restaurantId :>> ", restaurant_id)

    const response = await axios.get(`${baseURL}/qrcodes/${restaurant_id}`)
    return response.data
  } catch (error) {
    console.error("Error fetching QR codes:", error)
    throw error
  }
}

export const saveQRCode = async (
  restaurant_id,
  codeName,
  codeValue,
  qrColor
) => {
  try {
    const qrCodeObj = {
      restaurant_id,
      codeName,
      codeValue,
      qrColor,
    }
    const response = await axios.post(`${baseURL}/qrcodes`, qrCodeObj)
    console.log("qrCodeObj", qrCodeObj)
    return response.data.id
  } catch (error) {
    console.error("Error saving QR code:", error)
    throw error
  }
}

export const deleteQRCode = async (codeId) => {
  try {
    await axios.delete(`${baseURL}/qrcodes/${codeId}`)
  } catch (error) {
    console.error("Error deleting QR code:", error)
    throw error
  }
}
