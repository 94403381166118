import {
  Backdrop,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material"
import { useTranslation } from "react-i18next"

export function MyDialog({
  isOpen,
  onClose,
  children,
  title,
  actions,
  isLoading,
}) {
  return (
    <Dialog open={isOpen} onClose={onClose} className="my-dialog">
      <DialogTitle>{title}</DialogTitle>

      <DialogContent>{children}</DialogContent>

      {actions ? <DialogActions>{actions}</DialogActions> : null}

      {isLoading ? (
        <Backdrop
          sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={isLoading}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
      ) : null}
    </Dialog>
  )
}

export function FormButtons({ onClose, onSave, onDelete, onOk }) {
  const { t } = useTranslation()

  return (
    <div className="flex-row g10">
      {typeof onDelete === "function" ? (
        <Button onClick={onDelete} color="error">
          {t("Delete")}
        </Button>
      ) : null}

      {typeof onClose === "function" ? (
        <Button onClick={onClose} className="gray">
          {t("Cancel")}
        </Button>
      ) : null}

      {typeof onSave === "function" ? (
        <Button onClick={onSave} variant="contained" color="primary">
          {t("Save")}
        </Button>
      ) : null}

      {typeof onOk === "function" ? (
        <Button onClick={onOk} variant="contained" color="primary">
          {t("Ok")}
        </Button>
      ) : null}
    </div>
  )
}
