import { createContext, useContext, useState, useCallback, useRef } from "react"

import { Alert, AlertTitle, Box } from "@mui/material"

const StateContext = createContext({
  setSuccessAlert: () => {},
  setErrorAlert: () => {},
})

export const AlertContextProvider = ({ children }) => {
  const [alert, setAlert] = useState(null)
  const closeTimeoutId = useRef()

  const closeAlertHandler = useCallback(() => {
    setAlert(null)
    closeTimeoutId.current = null
  }, [])

  const setAlertBase = useCallback(({ type, title, content, timeout }) => {
    if (!content) return

    const titleReady = typeof title === "string" ? title : type

    setAlert({ type, title: titleReady, content })

    if (typeof closeTimeoutId.current === "number") {
      clearTimeout(closeTimeoutId.current)
      closeTimeoutId.current = null
    }

    closeTimeoutId.current = setTimeout(closeAlertHandler, timeout || 5000)

    return closeTimeoutId.current
  }, [])

  const setSuccessAlert = useCallback(
    (content, timeout) => setAlertBase({ type: "success", content, timeout }),
    []
  )

  const setErrorAlert = useCallback(
    (content, timeout) => setAlertBase({ type: "error", content, timeout }),
    []
  )

  const closeAlert = useCallback((alertId) => {
    if (closeTimeoutId.current === alertId) {
      closeAlertHandler()
    }
  }, [])

  return (
    <StateContext.Provider
      value={{
        setSuccessAlert,
        setErrorAlert,
        closeAlert,
      }}
    >
      {alert ? (
        <Box className="notification">
          <Alert severity={alert.type}>
            {alert.title ? <AlertTitle>{alert.title}</AlertTitle> : null}

            <strong>{alert.content}</strong>
          </Alert>
        </Box>
      ) : null}
      {children}
    </StateContext.Provider>
  )
}

export const useAlert = () => useContext(StateContext)
