import React from "react"
import { Box, MenuItem, Select } from "@mui/material"
import { useStateContext } from "contexts/userContext"
import { languageButtons } from "constants/languageButtons"
import { FlexRowContainer } from "./AllHelpComponents"

export default function LangSwitcher() {
  const { language, setLanguage } = useStateContext()

  return (
    <Select
      value={language}
      onChange={(event) => setLanguage(event.target.value)}
      size="small"
      style={{backgroundColor: '#fff'}}
    >
      {languageButtons.map((button) => (
        <MenuItem key={button.code} value={button.code}>
          <FlexRowContainer sx={{gap: 1}}>
            <Box sx={{ width: "24px" }}>{button.flag}</Box>
            <Box>{button.label}</Box>
          </FlexRowContainer>
        </MenuItem>
      ))}
    </Select>
  )
}
