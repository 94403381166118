export const getInitialTranslations = () => {
  return { en: "", he: "", ru: "", fr: "", hu: "" }
}

export function prepareTranslations(translations) {
  const initialTranslations = getInitialTranslations()

  if (!translations || !(translations instanceof Object)) {
    return initialTranslations
  }

  Object.keys(initialTranslations).forEach((lang) => {
    if (typeof translations[lang] === "string") {
      // @ts-ignore
      initialTranslations[lang] = translations[lang]
    }
  })

  return initialTranslations
}
