import { Orders } from "pages/Orders";
import { Settings } from "pages/Settings";
import { Home } from "pages/Home";
import { Profile } from "pages/Profile";
import { Dishes } from "pages/Dishes";
import { Toppings } from "pages/Toppings";
import { Extras } from "pages/Extras";
import { Types } from "pages/Types";
import { Groups } from "pages/Groups";
import { Statistics } from "pages/Statistics";
import { QRCodes } from "pages/QRCodes";
import Documentation from "pages/Documentation";

import { FaHome, FaUser, FaUtensils, FaCog, FaQrcode, FaListAlt, FaBook } from 'react-icons/fa';
import DishesNew from "pages/DishesNew";

export const pagesDashboards = [
  { name: "Home", href: "/", pageType: "up", element: <Home />, icon: <FaHome /> },
  { name: "Profile", href: "/profile", pageType: "up", element: <Profile />, icon: <FaUser /> },
  // { name: "Toppings", href: "/toppings", pageType: "up", element: <Toppings />, icon: <FaUtensils /> },
  // { name: "Extras", href: "/extras", pageType: "up", element: <Extras />, icon: <FaUtensils /> },
  // { name: "Types", href: "/types", pageType: "up", element: <Types />, icon: <FaUtensils /> },
  { name: "Groups", href: "/groups", pageType: "up", element: <Groups />, icon: <FaUtensils /> },
  // { name: "Dishes", href: "/dishes", pageType: "up", element: <Dishes />, icon: <FaUtensils /> },
  { name: "Dishes *", href: "/dishes-new", pageType: "up", element: <DishesNew />, icon: <FaUtensils /> },
  { name: "Orders", href: "/orders", pageType: "up", element: <Orders />, icon: <FaListAlt /> },
  { name: "Settings", href: "/settings", pageType: "down", element: <Settings />, icon: <FaCog /> },
  { name: "QR Codes", href: "/qr-code-generator", pageType: "up", element: <QRCodes />, icon: <FaQrcode /> },
  { name: "Documentation", href: "/documentation", pageType: "down", element: <Documentation />, icon: <FaBook /> },
];

export const initialState = [
  {
    order_id: 2,
    queryId: 0,
    comment: "",
    totalPrice: "58.00",
    optionDelivery: "on_site",
    paymentMethod: "card",
    order_date: null,
    cartItems:
      '[{"id":2,"title":"Coca","price":8,"quantity":2},{"id":1,"title":"Falafel","price":15,"description":"Falafel_description","toppings":[],"quantity":2,"selectedToppings":[]}]',
    address: "",
    user_name: "yair770",
    user_id: 386212074,
  },

  {
    order_id: 9,
    queryId: 0,
    comment: "",
    totalPrice: "123123.00",
    optionDelivery: "on_site",
    paymentMethod: "card",
    order_date: null,
    cartItems:
      '[{"id":1,"title":"Falafel","price":15,"description":"Falafel_description","toppings":[],"quantity":2,"selectedToppings":[]}]',
    address: "",
    user_name: "yair770",
    user_id: 386212074,
  },
  {
    order_id: 10,
    queryId: 0,
    comment: "",
    totalPrice: "123123.00",
    optionDelivery: "on_site",
    paymentMethod: "card",
    order_date: null,
    cartItems:
      '[{"id":1,"title":"Falafel","price":15,"description":"Falafel_description","toppings":[],"quantity":2,"selectedToppings":[]}]',
    address: "",
    user_name: "yair770",
    user_id: 386212074,
  },
];

const developmentURL = "http://localhost:5005";
// const productionURL = "https://2910273-ca28586.twc1.net";
const productionURL = "https://94.241.171.18";
// const productionURL = "https://burgerim.ru";

// export const baseURL =
//   process.env.NODE_ENV === "development" ? developmentURL : productionURL;

export const baseURL =
  process.env.REACT_APP_STAGE === "local" ? developmentURL : productionURL;

// module.exports = { pagesDashboards, initialState };

export const creditCardInitialData = {
  cardNumber: "1234567890123456",
  expiryDate: "12/23",
  cvv: "123",
  email: "john.doe@example.com",
}