import * as React from "react";
import { styled, useTheme } from "@mui/material/styles";
import MuiAppBar from "@mui/material/AppBar";
import MenuIcon from "@mui/icons-material/Menu";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import {
  Box,
  Drawer,
  Toolbar,
  CssBaseline,
  Typography,
  List,
  Divider,
  IconButton,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
} from "@mui/material";
import { useAuth0 } from "@auth0/auth0-react";
import { pagesDashboards } from "constants/constants";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import LangSwitcher from "./LangSwitcher";

const drawerWidth = 240;
const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  transition: theme.transitions.create(["margin", "width"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginRight: drawerWidth,
  }),
}));

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  padding: theme.spacing(0, 1),
  ...theme.mixins.toolbar,
  justifyContent: "flex-start",
}));

const MenuBarStyle = {
  "display": "none",
  "@media(max-width: 740px)": {
    display: "flex",
  },
};

export function MenuBar() {
  const hebrewDate = new Intl.DateTimeFormat("en-u-ca-hebrew", {
    year: "numeric",
    month: "numeric",
    day: "numeric",
  }).format(new Date());

  const gregorianDate = new Date();
  const gregorianDateStr = gregorianDate.toLocaleDateString("en-GB", {
    day: "numeric",
    month: "long",
    year: "numeric",
  });

  const theme = useTheme();
  const [open, setOpen] = React.useState(false);
  const { t } = useTranslation();

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const handleMenuItemClick = () => {
    handleDrawerClose();
  };

  const { isAuthenticated } = useAuth0();

  return (
    <>
      {isAuthenticated && (
        <Box sx={MenuBarStyle}>
          <CssBaseline />
          <AppBar position="fixed" open={open}>
            <Toolbar
              sx={{
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <LangSwitcher />

              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "space-between",
                  alignItems: "center",
                  width: "200px",
                  height: "50px",
                  padding: "5px",
                  borderRadius: "10px",
                  border: "1px solid #d0d0d0",
                  marginRight: "10px",
                  marginLeft: "10px",
                }}
              >
                <Typography
                  noWrap
                  sx={{
                    flexGrow: 1,
                    textAlign: "center",
                  }}
                  component="div"
                >
                  {hebrewDate}
                </Typography>
                <Typography
                  noWrap
                  sx={{
                    flexGrow: 1,
                    textAlign: "center",
                  }}
                  component="div"
                >
                  {gregorianDateStr}
                </Typography>
              </Box>

              <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
                <IconButton
                  color="inherit"
                  aria-label="open drawer"
                  edge="end"
                  onClick={handleDrawerOpen}
                  sx={{ display: open ? "none" : "block" }}
                >
                  <MenuIcon />
                </IconButton>
              </Box>
            </Toolbar>
          </AppBar>
          <DrawerHeader />
          <Drawer
            sx={{
              "width": drawerWidth,
              "flexShrink": 0,
              "& .MuiDrawer-paper": {
                width: drawerWidth,
              },
            }}
            variant="persistent"
            anchor="right"
            open={open}
          >
            <DrawerHeader>
              <IconButton onClick={handleDrawerClose}>
                {theme.direction === "rtl" ? <ChevronLeftIcon /> : <ChevronRightIcon />}
              </IconButton>
            </DrawerHeader>

            <Divider />
            <List>
              {/* list buttons============== */}
              {pagesDashboards.map(
                (pagesDashboard) =>
                  pagesDashboard.pageType === "up" && (
                    <Link
                      key={pagesDashboard.name}
                      onClick={handleDrawerClose}
                      to={pagesDashboard.href}
                      style={{ textDecoration: "none" }}
                    >
                      <ListItem disablePadding>
                        <ListItemButton onClick={() => handleMenuItemClick()}>
                          <ListItemIcon>{pagesDashboard.icon}</ListItemIcon>
                          <ListItemText primary={t(pagesDashboard.name)} />
                        </ListItemButton>
                      </ListItem>
                    </Link>
                  )
              )}
            </List>
            <Divider />
            <List>
              {pagesDashboards.map(
                (pagesDashboard) =>
                  pagesDashboard.pageType === "down" && (
                    <Link
                      key={pagesDashboard.name}
                      onClick={handleDrawerClose}
                      to={pagesDashboard.href}
                      style={{ textDecoration: "none" }}
                    >
                      <ListItem disablePadding>
                        <ListItemButton onClick={() => handleMenuItemClick()}>
                          <ListItemIcon>{pagesDashboard.icon}</ListItemIcon>
                          <ListItemText primary={t(pagesDashboard.name)} />
                        </ListItemButton>
                      </ListItem>
                    </Link>
                  )
              )}
            </List>
          </Drawer>
        </Box>
      )}
    </>
  );
}
