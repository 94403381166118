import {useAuth0} from "@auth0/auth0-react"
import Avatar from "@mui/material/Avatar"
import Typography from "@mui/material/Typography"
import Box from "@mui/material/Box"
import {FlexColumnContainer} from "styles/styledComponents"
import {useStateContext} from "contexts/userContext"
import { useEffect, useState } from "react"
import { getRestaurant } from "helpers/api"
import { Button } from "@mui/material"
import { useNavigate } from "react-router-dom"
import { formatDateForView } from "helpers/date"
import { useTranslation } from "react-i18next"
 
export const Profile = () => {
  const {settings,restaurant_id} = useStateContext()
  const {user, isAuthenticated} = useAuth0()
  const [restaurant, setRestaurant] = useState(null);  
  const navigate = useNavigate();
  const {t} = useTranslation();

  useEffect(()=> {
    getRestaurant(restaurant_id).then(data => {
      if (!data)return;
     
      setRestaurant(data);
    });
   
  }, [restaurant_id]);

  const onSubscriptionBtnClick = () => {
    navigate("/subscription")
  }
  
  // {
  //   (settings?.logoImage || user?.picture) && (
  //     // {user?.picture && (
  //     <img
  //       className="avatar_img"
  //       style={{width: "50px", margin: "10px 10px 10px 40px"}}
  //       src={settings?.logoImage ? settings?.logoImage : user?.picture}
  //       alt={user?.nickname}
  //     />
  //   )
  // }

  const {subscription_start_date, subscription_end_date, is_subscription_active} = restaurant || {};

  const subscriptionStartDateStr = subscription_start_date ? formatDateForView(new Date(subscription_start_date)) : null;
  const subscriptionEndDateStr = subscription_end_date ? formatDateForView(new Date(subscription_end_date)) : null;

  return (
    isAuthenticated && (
      <FlexColumnContainer sx={{m: 3}}>
        
        <Box> ID : {restaurant_id}</Box>
        
        {(settings?.logoImage || user?.picture) && (
          <Avatar
            sx={{width: 100, height: 100, margin: "auto"}}
            src={settings?.logoImage ? settings?.logoImage : user?.picture}
            alt={user?.name}
          />
        )}
        {/* <Typography variant="h4">{user?.name}</Typography> */}

        {Object.keys(user).map((objKey, i) => (
          <Box key={i}>
            <Typography sx={{mt: 2}} variant="h5">
              {t(objKey)}
            </Typography>
            <Typography noWrap>{user[objKey]}</Typography>
          </Box>
        ))}

        {subscriptionStartDateStr && subscriptionEndDateStr
          ? (
            <Box>
              <Typography sx={{mt: 2}} variant="h5">
                {t('Subscription')}
              </Typography>
              <Typography noWrap>
                {subscriptionStartDateStr} - {subscriptionEndDateStr} {' '}

                <Button
                  variant="contained"
                  color="primary"
                  onClick={onSubscriptionBtnClick}
                >
                  {is_subscription_active ? t('Renew your subscription') : t('Buy a subscription')}
                </Button>
                
              </Typography>
            </Box>
          )
          : null}
      </FlexColumnContainer>
    )
  )
}
