import { Autocomplete, Box, Button, FormControl, FormLabel, IconButton, Input, TextField } from "@mui/material";
import { FullSizeTextarea } from "styles/styledComponents";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import { useCallback, useEffect, useState } from "react";
import { FormButtons, MyDialog } from "./MyDialog";
import { deleteDish, getAddons, getChoices, postDish, putDish } from "helpers/api";
import { useAlert } from "contexts/alertContext";
import { TranslationForm } from "./TranslationForm";
import MyTabs from "./MyTabs";
import { DishAddonForm } from "./DishAddonForm";
import { IoLanguage } from "react-icons/io5";
import { useTranslation } from "react-i18next";
import { useStateContext } from "contexts/userContext";

export function DishForm({ restaurant_id, data, groups, onClose, onSuccess, onDelete }) {
  const [formState, setFormState] = useState(data);
  const [errorAlertId, setErrorAlertId] = useState(null);
  const [isLoading, setIsLoading] = useState(null);
  const [translationProp, setTranslationProp] = useState(null);

  const { setSuccessAlert, setErrorAlert, closeAlert } = useAlert();

  const { t } = useTranslation();
  const { language } = useStateContext();

  useEffect(() => {
    getAddons(data.id).then((addons) => {
      setFormState((curFormState) => ({ ...curFormState, addons }));
    });
    getChoices(data.id).then((choices) => {
      setFormState((curFormState) => ({ ...curFormState, choices }));
    });
  }, []);

  const handleEditChange = useCallback((name, value) => {
    setFormState((currentState) => ({ ...currentState, [name]: value }));
  }, []);

  const validateForm = useCallback(
    (dish) => {
      if (!dish.title || !dish.price) {
        setErrorAlertId(setErrorAlert(t("Please fill Title andPrice fields")));

        return false;
      }

      const price = parseFloat(dish.price);
      if (!price || price <= 0) {
        setErrorAlertId(setErrorAlert(t("The Price field must contain number more than 0")));

        return false;
      }

      if (errorAlertId !== null) {
        closeAlert(errorAlertId);
        setErrorAlertId(null);
      }

      return true;
    },
    [errorAlertId]
  );

  const handleDelete = useCallback(async () => {
    await deleteDish(data.id);
    onDelete(data.id);

    setSuccessAlert(t("Object deleted successful", { name: data.title }));
  }, [data, onDelete]);

  const onSave = useCallback(async () => {
    if (!validateForm(formState)) return;

    setIsLoading(true);

    if (formState.id) {
      await putDish(formState.id, formState);
      setIsLoading(false);

      onSuccess(formState);
      setSuccessAlert(t("Object updated successful", { name: data.title }));

      return;
    }

    const responseData = await postDish({
      ...formState,
      restaurant_id: restaurant_id,
    });
    setIsLoading(false);

    onSuccess({ ...formState, id: responseData.insertId });

    setSuccessAlert(t("Object created successful", { name: data.title }));
  }, [formState]);

  const generalTabContent = (
    <div className="my-form flex-column g15" style={{ width: "600px", maxWidth: "100%" }}>
      <FormControl required fullWidth>
        <FormLabel>{t("Name")}</FormLabel>
        <div className="flex-row flex-y-center g10">
          {console.log("formState", formState)}
          <TextField
     value={
      formState.translations?.[language]?.trim()
        ? formState.translations[language]
        : formState.title?.trim() || ""
    }
            onChange={(e) => handleEditChange("title", e.target.value)}
            fullWidth
          />

          <IconButton onClick={() => setTranslationProp("translations")}>
            <IoLanguage />
          </IconButton>
        </div>
      </FormControl>

      <FormControl fullWidth>
        <FormLabel>{t("Description")}</FormLabel>
        <div className="flex-row g10">
          <FullSizeTextarea
            // value={formState.description || ""}

            value={
              formState.translations_descriptions?.[language]?.trim()
                ? formState.translations_descriptions[language]
                : formState.description?.trim() || ""
            }
            onChange={(e) => handleEditChange("description", e.target.value)}
            label={t("Description")}
          />

          <div>
            <IconButton onClick={() => setTranslationProp("translations_descriptions")}>
              <IoLanguage />
            </IconButton>
          </div>
        </div>
      </FormControl>

      <FormControl required fullWidth>
        <FormLabel>{t("Price")}</FormLabel>
        <TextField
          type="number"
          InputProps={{
            inputProps: { step: 0.1, min: 0 }, // Установка атрибутов input
            style: { width: "200px" },
          }}
          value={formState.price || ""}
          onChange={(e) => handleEditChange("price", e.target.value)}
          placeholder="0.00"
        />
      </FormControl>

      <FormControl fullWidth>
        <FormLabel>{t("Image Preview")}</FormLabel>
        <Box>
          {formState.image ? (
            <div className="mb5">
              <img src={formState.image} style={{ maxWidth: "200px", maxHeight: "160px" }} alt=" " />
            </div>
          ) : null}

          <label htmlFor="imgUpload">
            <Button
              variant="contained"
              component="span"
              startIcon={<CloudUploadIcon />}
              sx={{
                "borderRadius": 8,
                "backgroundColor": "#2196f3",
                "color": "#fff",
                "&:hover": { backgroundColor: "#1976d2" },
              }}
            >
              {formState.image ? t("Upload new") : t("Upload")}
            </Button>
          </label>

          <Input
            id="imgUpload"
            type="file"
            inputProps={{
              accept: "image/*",
              style: { display: "none" },
            }}
            onChange={(e) => {
              const file = e.target.files[0];
              const reader = new FileReader();
              if (!file) return;

              reader.readAsDataURL(file);
              reader.onloadend = () => {
                handleEditChange("image", reader.result);
              };
            }}
            required
          />
        </Box>
      </FormControl>

      {groups && (
        <FormControl fullWidth>
          <FormLabel>{t("Group")}</FormLabel>
          <Autocomplete
            options={groups}
            getOptionLabel={(option) => option.name}
            value={groups.find((group_obj) => group_obj.id === formState.group_obj?.id) || null}
            filterSelectedOptions
            onChange={(event, newValue) => {
              handleEditChange("group_obj", newValue);
            }}
            isOptionEqualToValue={(option, value) => option?.id === value?.id}
            renderInput={(params) => <TextField {...params} />}
          />
        </FormControl>
      )}
    </div>
  );

  const choicesTabContent = (
    <div
      style={{
        width: "600px",
        maxWidth: "100%",
        minHeight: "608px",
      }}
    >
      {console.log("formState.choices", formState.choices)}
      {formState.choices ? (
        <DishAddonForm
          newItemName={t("New choice")}
          addingBtnText={t("Add choice")}
          items={formState.choices}
          onChange={(newValue) => {
            handleEditChange("choices", newValue);
          }}
        />
      ) : null}
    </div>
  );

  const addonsTabContent = (
    <div
      style={{
        width: "600px",
        maxWidth: "100%",
        minHeight: "608px",
      }}
    >
      {formState.addons ? (
        <DishAddonForm
          newItemName={t("New addon")}
          addingBtnText={t("Add addon")}
          withMaxSelectedField={true}
          items={formState.addons}
          onChange={(newValue) => {
            handleEditChange("addons", newValue);
          }}
        />
      ) : null}
    </div>
  );

  return (
    <>
      <MyDialog
        isOpen={true}
        title={data.title || t("Creating dish")}
        onClose={onClose}
        isLoading={isLoading}
        actions={<FormButtons {...{ onDelete: data.id ? handleDelete : null, onClose, onSave }} />}
      >
        <MyTabs
          tabs={[
            {
              label: t("General"),
              content: generalTabContent,
            },
            {
              label: t("Choices"),
              content: choicesTabContent,
            },
            {
              label: t("Addons"),
              content: addonsTabContent,
            },
          ]}
        />
      </MyDialog>

      {translationProp ? (
        <TranslationForm
          basePhrase={translationProp === "translations_descriptions" ? formState.description : formState.title}
          translations={formState[translationProp]}
          onClose={() => setTranslationProp(null)}
          onSave={(newTranslationsState) => {
            setFormState((curState) => ({
              ...curState,
              [translationProp]: newTranslationsState,
            }));
            setTranslationProp(null);
          }}
        />
      ) : null}
    </>
  );
}
