import React, { useCallback, useState } from "react"
import { Button, TextField } from "@mui/material"
import { FormButtons, MyDialog } from "./MyDialog"
import { getTranslationsForText } from "utils/translationUtils"
import { useAlert } from "contexts/alertContext"
import { prepareTranslations } from "helpers/translations"
import { useTranslation } from "react-i18next"

export const TranslationForm = ({
  basePhrase,
  translations,
  onSave,
  onClose,
}) => {
  const [isLoading, setIsLoading] = useState(false)
  const [fieldsState, setFieldsState] = useState(
    prepareTranslations(translations)
  )

  const { setSuccessAlert, setErrorAlert } = useAlert()
  const { t } = useTranslation()

  const onTranslationChange = useCallback((e) => {
    setFieldsState((fieldsState) => ({
      ...fieldsState,
      [e.target.name]: e.target.value,
    }))
  }, [])

  const handleAutoTranslate = useCallback(async () => {
    try {
      setIsLoading(true)
      const translationObj = await getTranslationsForText(basePhrase)
      setIsLoading(false)

      setFieldsState(translationObj)

      setSuccessAlert(t("Auto-translation completed successfully"))
    } catch (error) {
      console.error("Error in auto-translation:", error)

      setErrorAlert(t("Auto-translation error"))
    }
  }, [basePhrase])

  return (
    <MyDialog
      isOpen={true}
      title={t("Translations")}
      onClose={onClose}
      isLoading={isLoading}
      actions={
        <FormButtons {...{ onClose, onOk: () => onSave(fieldsState) }} />
      }
    >
      <Button variant="outlined" color="primary" onClick={handleAutoTranslate}>
        {t("Auto Translate")}
      </Button>

      {Object.keys(fieldsState).map((lang) => (
        <TextField
          key={lang}
          name={lang}
          label={lang.toUpperCase()}
          value={fieldsState[lang] || ""}
          onChange={onTranslationChange}
          fullWidth
          margin="normal"
        />
      ))}
    </MyDialog>
  )
}
