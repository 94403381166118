import React from 'react';
import { Typography, Container, Paper, Box } from '@mui/material';

const Documentation = () => {
  return (
    <Container maxWidth="lg">
      <Paper elevation={3} sx={{ padding: 4, marginTop: 4, marginBottom: 4 }}>
        <Typography variant="h4" component="h1" gutterBottom>
          How to Use the Platform
        </Typography>
        <Box component="ol" sx={{ marginTop: 2 }}>
          <Typography component="li" paragraph>
            <strong>Registration and Login:</strong> Create an account or log in using your credentials.
          </Typography>
          <Typography component="li" paragraph>
            <strong>Profile Setup:</strong> Go to the "Profile" section and fill in the necessary information about your business.
          </Typography>
          <Typography component="li" paragraph>
            <strong>Menu Creation:</strong>
            <Box component="ol" type="a">
              <Typography component="li">Go to the "Types" section and add categories of dishes (e.g., burgers, drinks).</Typography>
              <Typography component="li">In the "Dishes" section, add specific menu items, specifying the name, description, price, and category.</Typography>
              <Typography component="li">Add additional ingredients in the "Toppings" section, if necessary.</Typography>
              <Typography component="li">In the "Extras" section, add options that can be ordered alongside main dishes.</Typography>
            </Box>
          </Typography>
          <Typography component="li" paragraph>
            <strong>Order Management:</strong> In the "Orders" section, you can view and process incoming orders.
          </Typography>
          <Typography component="li" paragraph>
            <strong>Group Creation:</strong> Use the "Groups" section to organize dishes into special offers or combo sets.
          </Typography>
          <Typography component="li" paragraph>
            <strong>QR Code Generation:</strong> In the "QR Codes" section, create and manage QR codes for quick access to the menu.
          </Typography>
          <Typography component="li" paragraph>
            <strong>Statistics Analysis:</strong> Use the "Statistics" section to view data on sales and dish popularity.
          </Typography>
          <Typography component="li" paragraph>
            <strong>System Setup:</strong> In the "Settings" section, you can modify general platform parameters such as currency, language, and notifications.
          </Typography>
          <Typography component="li" paragraph>
            <strong>Regular Updates:</strong> Don’t forget to update the menu, prices, and information about your business to keep everything up-to-date.
          </Typography>
        </Box>
        <Typography variant="body1" sx={{ marginTop: 2 }}>
          For more detailed help on each section, refer to the detailed instructions available in the corresponding sections of the platform.
        </Typography>
      </Paper>
    </Container>
  );
};

export default Documentation;