import React, { useState, useEffect } from "react";
import {
  Typography,
  Box,
  IconButton,
  Dialog,
  DialogContent,
} from "@mui/material";
import { useLocation } from "react-router-dom";
import theme from "styles/theme";
import { useAuth0 } from "@auth0/auth0-react";
import InfoIcon from "@mui/icons-material/Info";
import { useTranslation } from "react-i18next";

export const Header = () => {
  const { isAuthenticated } = useAuth0();
  const location = useLocation();
  const [open, setOpen] = useState(false);
  const [imageExists, setImageExists] = useState(false);
  const {t} = useTranslation()

  // Извлекаем имя страницы из пути
  const namePage = location.pathname.slice(1);

  // Проверка доступности изображения
  const checkImageExists = (path) => {
    return new Promise((resolve) => {
      const img = new Image();
      img.src = path;
      img.onload = () => resolve(true);
      img.onerror = () => resolve(false);
    });
  };

  useEffect(() => {
    if (!namePage) {
      setImageExists(false); // Обнуляем состояние
      return;
    }

    const imagePath = `/images/instructions/${namePage}/${namePage}.png`;

    // Проверяем существование изображения
    checkImageExists(imagePath).then((exists) => {
      setImageExists(exists);
      console.log(`Image exists for ${namePage}:`, exists);
    });
  }, [namePage]); // Выполняется при изменении маршрута

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  // Форматирование названия страницы
  function capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }

  return (
    <>
      {isAuthenticated && (
        <Box
          sx={{
            width: "100%",
            backgroundColor: theme.blue2,
            textAlign: "center",
            position: "relative",
          }}
        >
          <Typography variant="h4" p="10px">
            {!namePage ? t("Home Page") : t(capitalizeFirstLetter(namePage))}
          </Typography>

          {/* Кнопка отображается только если изображение доступно */}
          {imageExists && (
            <IconButton
              sx={{ position: "absolute", top: "10px", right: "10px" }}
              color="inherit"
              onClick={handleClickOpen}
            >
              <InfoIcon />
            </IconButton>
          )}

          {/* Диалог с изображением */}
          <Dialog open={open} onClose={handleClose}>
            <DialogContent>
              <img
                src={`/images/instructions/${namePage}/${namePage}.png`}
                alt="instruction"
                style={{ width: "100%" }}
              />
            </DialogContent>
          </Dialog>
        </Box>
      )}
    </>
  );
};