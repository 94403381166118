import axios from "axios"
import { baseURL } from "constants/api"

const apiRequest = async (method, pathname, data) => {
  try {
    const response = await axios.request({
      method,
      url: `${baseURL}/${pathname}`,
      data: method !== "GET" ? data : null,
    })
    return response.data
  } catch (error) {
    console.error(`Ошибка при выполнении запроса ${pathname}:`, error)
    throw error
  }
}

const apiGet = async (pathname) => apiRequest("GET", pathname)
const apiPut = async (pathname, body) => apiRequest("PUT", pathname, body)
const apiPost = async (pathname, body) => apiRequest("POST", pathname, body)
const apiDelete = async (pathname) => apiRequest("DELETE", pathname)

export const getRestaurant = (restaurant_id) =>
  apiGet(`restaurants/${restaurant_id}`).then((res) => res[0])

export const getExtras = (restaurant_id) => apiGet(`extras/${restaurant_id}`)

export const getSettings = (restaurant_id) =>
  apiGet(`settings/${restaurant_id}`)

export const getTypes = (restaurant_id) => apiGet(`types/${restaurant_id}`)

export const getToppings = (restaurant_id) =>
  apiGet(`toppings/${restaurant_id}`)

export const getDishes = (restaurant_id) => apiGet(`dishes/${restaurant_id}`)
export const putDish = (id, data) => apiPut(`dishes/${id}`, data)
export const postDish = (data) => apiPost(`dishes/`, data)
export const deleteDish = (id) => apiDelete(`dishes/${id}`)
export const getAddons = (dish_id) => apiGet(`dishes/${dish_id}/addons`)
export const getChoices = (dish_id) => apiGet(`dishes/${dish_id}/choices`)

export const getGroups = (restaurant_id) => apiGet(`groups/${restaurant_id}`)
export const putGroup = (data) => apiPut(`groups/`, data)
export const postGroup = (data) => apiPost(`groups/`, data)
export const deleteGroup = (id) => apiDelete(`groups/${id}`)
