import { FormControl, FormLabel, IconButton, TextField } from "@mui/material"
import { useCallback, useState } from "react"
import { FormButtons, MyDialog } from "./MyDialog"
import { deleteGroup, postGroup, putGroup } from "helpers/api"
import { useAlert } from "contexts/alertContext"
import { TranslationForm } from "./TranslationForm"
import { IoLanguage } from "react-icons/io5"
import { useTranslation } from "react-i18next"

export function DishGroupForm({
  restaurant_id,
  data,
  onClose,
  onSuccess,
  onDelete,
}) {
  const [formState, setFormState] = useState(data)
  const [errorAlertId, setErrorAlertId] = useState(null)
  const [isLoading, setIsLoading] = useState(null)
  const [translationProp, setTranslationProp] = useState(null)

  const { setSuccessAlert, setErrorAlert, closeAlert } = useAlert()
  const { t } = useTranslation()

  const handleEditChange = useCallback((name, value) => {
    setFormState((currentState) => ({ ...currentState, [name]: value }))
  }, [])

  const validateForm = useCallback(
    (dish) => {
      if (!dish.name) {
        setErrorAlertId(setErrorAlert(t("Please fill name")))

        return false
      }

      if (errorAlertId !== null) {
        closeAlert(errorAlertId)
        setErrorAlertId(null)
      }

      return true
    },
    [errorAlertId]
  )

  const handleDelete = useCallback(async () => {
    await deleteGroup(data.id)
    onDelete(data.id)
    setSuccessAlert(t("Object deleted successful", { name: data.name }))
  }, [data, onDelete])

  const onSave = useCallback(async () => {
    if (!validateForm(formState)) return

    setIsLoading(true)

    if (formState.id) {
      await putGroup({
        ...formState,
        restaurant_id: restaurant_id,
      })
      setIsLoading(false)

      onSuccess(formState)

      setSuccessAlert(t("Object updated successful", { name: formState.name }))

      return
    }

    const responseData = await postGroup({
      ...formState,
      restaurant_id: restaurant_id,
    })
    setIsLoading(false)

    onSuccess({ ...formState, id: responseData.insertId })

    setSuccessAlert(t("Object created successful", { name: formState.name }))
  }, [formState])

  return (
    <>
      <MyDialog
        isOpen={true}
        title={data.title || t("Creating dish group")}
        onClose={onClose}
        isLoading={isLoading}
        actions={
          <FormButtons
            {...{ onDelete: data.id ? handleDelete : null, onClose, onSave }}
          />
        }
      >
        <div
          className="my-form flex-column g15"
          style={{ width: "600px", maxWidth: "100%" }}
        >
          <FormControl required fullWidth>
            <FormLabel>{t('Name')}</FormLabel>
            <div className="flex-row flex-y-center g10">
              <TextField
                value={formState.name || ""}
                onChange={(e) => handleEditChange("name", e.target.value)}
                fullWidth
              />

              <IconButton onClick={() => setTranslationProp("translations")}>
                <IoLanguage />
              </IconButton>
            </div>
          </FormControl>
        </div>
      </MyDialog>

      {translationProp ? (
        <TranslationForm
          basePhrase={
            translationProp === "translations" ? formState.name : null
          }
          translations={formState[translationProp]}
          onClose={() => setTranslationProp(null)}
          onSave={(newTranslationsState) => {
            setFormState((curState) => ({
              ...curState,
              [translationProp]: newTranslationsState,
            }))
            setTranslationProp(null)
          }}
        />
      ) : null}
    </>
  )
}
