import React, { useState, useEffect, useRef } from "react"
import QRCode from "qrcode.react"
import { saveQRCode, deleteQRCode, getQRCodes } from "../data/qrCodes"
import "../styles/QRCodeGenerator.scss"
import { useStateContext } from "contexts/userContext"
import { FlexColumnContainer, FlexRowContainer } from "styles/styledComponents"
import { Button } from "@mui/material"
import DeleteIcon from "@mui/icons-material/Delete"
import PrintIcon from "@mui/icons-material/Print"
import SaveIcon from "@mui/icons-material/Save"
import { useTranslation } from "react-i18next"

export const QRCodes = () => {
  const [qrCodes, setQRCodes] = useState([])
  const [inputValue, setInputValue] = useState("")
  const [error, setError] = useState(null)
  const [qrColor, setQRColor] = useState("#000000") // Состояние для цвета QR-кода
  const [errorCorrectionLevel, setErrorCorrectionLevel] = useState("L") // Состояние для уровня ошибок QR-кода
  const { restaurant_id } = useStateContext()
  const qrCodeRefs = useRef({})

  const {t} = useTranslation()

  useEffect(() => {
    fetchQRCodes()
  }, [restaurant_id])

  const fetchQRCodes = async () => {
    try {
      const codes = await getQRCodes(restaurant_id)
      setQRCodes(codes)
      setError(null)
    } catch (error) {
      setError(t("Failed to fetch QR codes. Please try again."))
    }
  }

  const handleInputChange = (e) => {
    setInputValue(e.target.value)
  }

  const handleColorChange = (e) => {
    setQRColor(e.target.value)
  }

  const handleAddQRCode = async () => {
    if (inputValue.trim() !== "") {
      try {
        const site_url = "https://burger-web-app.netlify.app/"
        const qrCodeValue = `${site_url}?restaurant_id=${restaurant_id}&qr_name=${encodeURIComponent(inputValue)}`

        console.log(
          "restaurant_id, inputValue, qrCodeValue, qrColor :>> ",
          restaurant_id,
          inputValue,
          qrCodeValue,
          qrColor
        )
        await saveQRCode(restaurant_id, inputValue, qrCodeValue, qrColor)

        setQRCodes([
          ...qrCodes,
          {
            id: restaurant_id,
            code_name: inputValue,
            code_value: qrCodeValue,
            qr_color: qrColor,
          },
        ])

        setInputValue("")
        setError(null)
      } catch (error) {
        setError(t("Failed to add QR code. Please try again."))
      }
    }
  }

  const handleDeleteQRCode = async (codeId) => {
    try {
      await deleteQRCode(codeId)
      setQRCodes(qrCodes.filter((code) => code.id !== codeId))
      setError(null)
    } catch (error) {
      setError(t("Failed to delete QR code. Please try again."))
    }
  }

  const handlePrintQRCode = (qrCode) => {
    const printWindow = window.open("", "_blank")
    printWindow.document.write(
      `<html><head><title>${t('Print QR Code')}</title></head><body>`
    )
    printWindow.document.write("<h1>" + t(qrCode.code_name) + "</h1>")
    printWindow.document.write(qrCodeRefs.current[qrCode.id].innerHTML)
    printWindow.document.write("</body></html>")
    printWindow.document.close()
    printWindow.print()
  }

  const handleSaveQRCodeSVG = (qrCode) => {
    const svg = qrCodeRefs.current[qrCode.id].querySelector("svg")
    const svgData = new XMLSerializer().serializeToString(svg)

    const downloadLink = document.createElement("a")
    downloadLink.download = `${qrCode.code_name}_qr_code.svg`
    downloadLink.href = "data:image/svg+xml;base64," + btoa(svgData)
    downloadLink.click()
  }

  const handleSaveQRCode = (qrCode) => {
    const svg = qrCodeRefs.current[qrCode.id].querySelector("svg")
    const svgData = new XMLSerializer().serializeToString(svg)

    const canvas = document.createElement("canvas")
    const bbox = svg.getBBox()
    canvas.width = bbox.width * 8 // увеличиваем разрешение в 8 раз
    canvas.height = bbox.height * 8

    const img = new Image()
    img.onload = () => {
      const ctx = canvas.getContext("2d")
      ctx.imageSmoothingEnabled = true // включаем сглаживание
      ctx.imageSmoothingQuality = "high" // устанавливаем высокое качество сглаживания
      ctx.drawImage(img, 0, 0, canvas.width, canvas.height)

      canvas.toBlob(
        (blob) => {
          const downloadLink = document.createElement("a")
          downloadLink.download = `${qrCode.code_name}_qr_code.png`
          downloadLink.href = URL.createObjectURL(blob)
          downloadLink.click()
        },
        "image/png",
        0.98 // устанавливаем очень высокое качество сжатия (98%)
      )
    }

    img.src = "data:image/svg+xml;base64," + btoa(svgData)
  }

  return (
    <div className="qr-code-generator">
      <h1>{t('Qr code generator')}</h1>
      {error && <div className="error-message">{error}</div>}
      <div className="input-container">
        <input
          type="text"
          value={inputValue}
          onChange={handleInputChange}
          placeholder={t("Enter QR code name (e.g., table number or street)")}
        />

        <button onClick={handleAddQRCode}>{t('Add')}</button>
      </div>

      {/* Color Picker */}
      {console.log("qrColor >> ", qrColor)}

      <div className="color-picker-wrapper">
        <input
          type="color"
          id="colorPicker"
          value={qrColor}
          onChange={handleColorChange}
          title={t("Choose QR code color")}
          // style={{backgroundColor: qrColor, color: "red", border: "3px solid green"}}
        />
        <label htmlFor="colorPicker" className="color-picker-label">
          <span style={{ margin: "10px" }} className="visually-hidden">
            {t("Choose QR code color")}
          </span>
        </label>
      </div>

      <FlexRowContainer>
        {console.log("qrCodes >> ", qrCodes)}
        {qrCodes.map((qr) => (
          <FlexColumnContainer key={qr.id} className="qr-code-item">
            <h2 style={{ color: qr.qr_color }}>{t(qr.code_name)}</h2>

            <div
              ref={(el) => (qrCodeRefs.current[qr.id] = el)}
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              {console.log("qr.qr_color >> ", qr.qr_color)}

              <QRCode
                value={qr.code_value}
                renderAs="svg"
                size={256}
                fgColor={qr.qr_color || "#000000"}
                level={errorCorrectionLevel}
                style={{ width: "100%", height: "auto" }}
              />
            </div>

            <FlexRowContainer>
              <Button
                variant="outlined"
                startIcon={<PrintIcon />}
                sx={{ width: "100%", color: "#4caf50", mt: 1 }}
                onClick={() => handlePrintQRCode(qr)}
              >
                {t('Print')}
              </Button>
              <Button
                variant="outlined"
                sx={{ width: "100%", color: "#4d27d7", mt: 1 }}
                onClick={() => window.open(qr.code_value, "_blank")}
              >
                {t('Link')}
              </Button>
            </FlexRowContainer>

            <FlexRowContainer>
              <Button
                variant="outlined"
                startIcon={<SaveIcon />}
                sx={{ color: "#2196f3", mt: 1 }}
                onClick={() => handleSaveQRCode(qr)}
              >
                {t('Save as Image')}
              </Button>

              <Button
                variant="outlined"
                startIcon={<SaveIcon />}
                sx={{ color: "#2196f3", mt: 1 }}
                onClick={() => handleSaveQRCodeSVG(qr)}
              >
                {t('Save as Vector')}
              </Button>
            </FlexRowContainer>

            <Button
              variant="outlined"
              startIcon={<DeleteIcon />}
              sx={{ width: "100%", color: "#ff6e6ede", mt: 1 }}
              onClick={() => handleDeleteQRCode(qr.id)}
            >
              {t('Delete')}
            </Button>
          </FlexColumnContainer>
        ))}
      </FlexRowContainer>
    </div>
  )
}
