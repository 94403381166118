import React from 'react';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Button,
  TextField,
  CircularProgress,
  Backdrop,
} from "@mui/material";
import { prepareTranslations } from 'helpers/translations';

export const TranslationModal = ({
  open,
  onClose,
  translations,
  onTranslationChange,
  onSave,
  onAutoTranslate,
  isLoading, currentTranslationField
}) => {
  const translationsData = prepareTranslations(translations)

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>Add Translations</DialogTitle>
      <DialogContent>
        <Button
          sx={{ml: 1}}
          variant="outlined"
          color="primary"
          onClick={onAutoTranslate}
          disabled={isLoading}
        >
          Auto Translate
        </Button>
        {Object.keys(translationsData).map((lang) => (

          <TextField
            key={lang}
            label={lang.toUpperCase()}
            value={translationsData[lang]}
            // value={translations?.title?.[lang] || ""}
            onChange={(e) => onTranslationChange(e, lang)}
            fullWidth
            margin="normal"
            disabled={isLoading}
          />
        ))}
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} disabled={isLoading}>Cancel</Button>
        <Button onClick={onSave} color="primary" disabled={isLoading}>
          OK
        </Button>
      </DialogActions>
      <Backdrop
        sx={{color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1}}
        open={isLoading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    </Dialog>
  );
};