import { Button, IconButton } from "@mui/material";
import { DishForm } from "components/DishForm";
import { DishGroupForm } from "components/DishGroupForm";
import { useStateContext } from "contexts/userContext";
import { getDishes, getExtras, getGroups, getToppings } from "helpers/api";
import { getInitialTranslations } from "helpers/translations";
import { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { FaPencilAlt, FaPlus, FaPlusCircle } from "react-icons/fa";

export default function DishesNew() {
  const { restaurant_id, language } = useStateContext();

  const [groups, setGroups] = useState([]);
  const [dishes, setDishes] = useState([]);
  const [extras, setExtras] = useState([]);
  const [toppings, setToppings] = useState([]);

  const [currentGroupId, setCurrentGroup] = useState(null);

  const [dishForEdit, setDishForEdit] = useState(null);
  const [editedDishGroup, setEditedDishGroup] = useState(null);

  const { t } = useTranslation();

  useEffect(() => {
    if (!restaurant_id) return;

    getDishes(restaurant_id).then(setDishes);
    getToppings(restaurant_id).then(setToppings);
    getExtras(restaurant_id).then(setExtras);

    getGroups(restaurant_id).then((data) => {
      setGroups(data);

      if (data[0]) {
        setCurrentGroup(data[0].id);
      }
    });
  }, []);

  const onTypeClick = (e) => {
    setCurrentGroup(+e.target.dataset.id);
  };

  const onDishGroupEdit = useCallback(
    (e) => {
      const id = +e.currentTarget.dataset.id;
      if (!id) {
        console.error("onDishGroupEdit dataset.id not found");
        return;
      }

      const data = groups.find((item) => item.id === id);
      if (!data) {
        console.error("onDishGroupEdit dishData not found");
        return;
      }

      setEditedDishGroup(data);
    },
    [groups]
  );

  const onDishGroupAdd = useCallback(() => {
    setEditedDishGroup({
      id: null,
      name: "",
      order: null,
      translations: getInitialTranslations(),
    });
  }, []);

  const onDishEdit = useCallback(
    (e) => {
      const id = +e.currentTarget.dataset.id;
      if (!id) {
        console.error("onDishEdit dataset.id not found");
        return;
      }

      const dishData = dishes.find((dish) => dish.id === id);
      if (!dishData) {
        console.error("onDishEdit dishData not found");
        return;
      }

      setDishForEdit(dishData);
    },
    [dishes]
  );

  const onDishAdd = useCallback(() => {
    const currentGroupData = groups.find((group) => group.id === currentGroupId);

    const group_obj = currentGroupData
      ? {
          id: currentGroupData.id,
          name: currentGroupData.name,
        }
      : null;

    const newData = {
      id: null,
      title: "",
      description: "",
      price: null,
      toppings: [],
      extras: [],
      image: "",
      group_obj,
      translations: getInitialTranslations(),
      translations_descriptions: getInitialTranslations(),
    };

    setDishForEdit(newData);
  }, [currentGroupId, groups]);

  const onDishFormSuccess = useCallback((dishData) => {
    setDishForEdit(null);

    if (!dishData.id) return;

    setDishes((currentDishes) => {
      const newDishes = [...currentDishes];

      const editedDishIndex = currentDishes.findIndex((dish) => dish.id === dishData.id);
      if (editedDishIndex === -1) {
        newDishes.push(dishData);
      } else {
        newDishes[editedDishIndex] = dishData;
      }

      return newDishes;
    });
  }, []);

  const onDishGroupFormSuccess = useCallback((data) => {
    setEditedDishGroup(null);

    if (!data.id) return;

    setGroups((currentItems) => {
      const newItems = [...currentItems];

      const editedItemIndex = currentItems.findIndex((dish) => dish.id === data.id);
      if (editedItemIndex === -1) {
        newItems.push(data);
      } else {
        newItems[editedItemIndex] = data;
      }

      return newItems;
    });
  }, []);

  const onDishFormClose = () => {
    setDishForEdit(null);
  };

  const onDishDelete = useCallback((id) => {
    setDishes((currentDishes) => {
      const deletedDishIndex = currentDishes.findIndex((dish) => dish.id === id);
      if (deletedDishIndex === -1) return;

      const newDishes = [...currentDishes];
      newDishes.splice(deletedDishIndex, 1);

      return newDishes;
    });

    setDishForEdit(null);
  }, []);

  const onDishGroupDelete = useCallback(
    (id) => {
      setGroups((currentItems) => {
        const deletedIndex = currentItems.findIndex((item) => item.id === id);
        if (deletedIndex === -1) return;

        const newItems = [...currentItems];
        newItems.splice(deletedIndex, 1);

        return newItems;
      });

      if (currentGroupId === id) {
        setCurrentGroup(groups[0].id);
      }

      setEditedDishGroup(null);
    },
    [groups, currentGroupId]
  );

  return (
    <>
      <div className="flex-column g10 p10">
        <div className="flex-row scrolled-x g10" style={{ paddingBottom: "5px" }}>
          <div className="nowrap">
            <Button startIcon={<FaPlus size={12} />} variant="outlined" onClick={onDishGroupAdd}>
              {t("Add")}
            </Button>
          </div>
          {console.log("groups", groups)}
          {console.log("language", language)}
          {groups.map((group) => (
            <div className="flex-row g10 flex-y-center nowrap" key={group.id}>
              <Button
                data-id={group.id}
                variant={currentGroupId === group.id ? "contained" : "outlined"}
                onClick={onTypeClick}
              >
                {group.translations[language] && group.translations[language].trim() !== ""
                  ? group.translations[language]
                  : group.name}
              </Button>

              <IconButton onClick={onDishGroupEdit} data-id={group.id} size="small">
                <FaPencilAlt />
              </IconButton>
            </div>
          ))}
        </div>

        <div className="flex-rows g10">
          <button
            className="border-light radius-default w200 center gray-bg-light-3"
            style={{ maxWidth: "calc(50% - 5px)" }}
            onClick={onDishAdd}
          >
            <span className="h160 flex-row flex-y-center flex-x-center">
              <FaPlusCircle size={50} className="gray-light" />
            </span>
            <span className="flex-column g10 p10">{t("Add new dish")}</span>
          </button>
          {console.log("dishes", dishes)}{" "}
          {dishes
            .filter((dish) => dish.group_obj?.id === currentGroupId)
            .map((dish) => (
              <div
                key={dish.id}
                className="border-light radius-default w200 center ovh pos-relative"
                style={{ maxWidth: "calc(50% - 5px)" }}
              >
                <div className="h160 bg-img-cover" style={{ backgroundImage: `url(${dish.image})` }}></div>

                <div className="flex-column g10 p10">
                  <div>
                    {dish.translations[language] && dish.translations[language].trim() !== ""
                      ? dish.translations[language]
                      : dish.title}
                  </div>
                </div>

                <div className="pos-absolute bg-white radius-full" style={{ right: "10px", top: "10px" }}>
                  <IconButton size="small" data-id={dish.id} onClick={onDishEdit}>
                    <FaPencilAlt />
                  </IconButton>
                </div>
              </div>
            ))}
        </div>
      </div>

      {dishForEdit ? (
        <DishForm
          data={dishForEdit}
          restaurant_id={restaurant_id}
          toppings={toppings}
          extras={extras}
          groups={groups}
          onClose={onDishFormClose}
          onSuccess={onDishFormSuccess}
          onDelete={onDishDelete}
        />
      ) : null}

      {editedDishGroup ? (
        <DishGroupForm
          data={editedDishGroup}
          restaurant_id={restaurant_id}
          onClose={() => setEditedDishGroup(null)}
          onSuccess={onDishGroupFormSuccess}
          onDelete={onDishGroupDelete}
        />
      ) : null}
    </>
  );
}
