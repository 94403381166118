import React, { useState, useEffect } from "react"
import axios from "axios"
import { useForm } from "react-hook-form"
import { Button, TextField } from "@mui/material"
import { Box } from "@mui/system"

import { FlexColumnContainer } from "components/AllHelpComponents"
import DialogComponent from "components/DialogComponent"
import { LoadingOverlay } from "components/LoadingOverlay"
import { baseURL, creditCardInitialData } from "constants/constants"
import { useStateContext } from "contexts/userContext"
import { useTranslation } from "react-i18next"

export const CardPayForm = ({ routePathname, services, onSuccess }) => {
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [dialogOpen, setDialogOpen] = useState(false)
  const { restaurant_id } = useStateContext()
  const {t} = useTranslation()

  const handleCloseDialog = () => {
    setDialogOpen(false)
  }

  const { register, handleSubmit, setValue } = useForm()

  async function payCreditCard(payData) {
    try {
      setIsSubmitting(true)
      await axios.post(baseURL + routePathname, {
        services,
        payData,
        restaurant_id,
      })
      setIsSubmitting(false)

      if (typeof onSuccess === "function") {
        onSuccess()
      }

      console.log('Запрос "payment/execute" успешно выполнен')
    } catch (error) {
      setIsSubmitting(false)
      console.error('Ошибка при выполнении запроса "payment/execute":', error)
      return
    }
  }

  useEffect(() => {
    setValue("cardNumber", creditCardInitialData.cardNumber)
    setValue("expiryDate", creditCardInitialData.expiryDate)
    setValue("cvv", creditCardInitialData.cvv)
    setValue("email", creditCardInitialData.email)
  }, [setValue])

  return (
    <>
      {isSubmitting ? <LoadingOverlay /> : null}

      <FlexColumnContainer>
        <Box component="form" onSubmit={handleSubmit(payCreditCard)}>
          <TextField
            {...register("cardNumber", {
              required: "Card number is required",
            })}
            label={"Card number"}
            sx={{ width: "100%", mb: 2 }}
          />

          <Box sx={{ display: "flex", gap: "16px", pb: 2 }}>
            <TextField
              {...register("expiryDate", {
                required: "Expiry date is required",
              })}
              label="Expity date"
              sx={{ flex: 1, mr: 2 }}
            />
            <TextField
              {...register("cvv", { required: "Cvv code is required" })}
              label="Cvv code"
              sx={{ flex: 1 }}
            />
          </Box>
          <TextField
            {...register("email", { required: "Email is required" })}
            label="Email"
            sx={{ width: "100%", mb: 2 }}
          />

          <Button type="submit" variant="contained">
            Submit
          </Button>
        </Box>
      </FlexColumnContainer>

      <DialogComponent
        dialogTitle="Success"
        text="Order successfully created!"
        buttonRight="Ok"
        open={dialogOpen}
        onClose={handleCloseDialog}
      />
    </>
  )
}
